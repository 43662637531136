body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0.15rem 0.8rem rgba(0, 0, 0, 0.2);
  background-color: #0168B7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%230168B7' cx='50' cy='0' r='50'/%3E%3Cg fill='%23086dbb' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%230f71bf' cx='50' cy='100' r='50'/%3E%3Cg fill='%231576c3' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%231b7bc6' cx='50' cy='200' r='50'/%3E%3Cg fill='%232080ca' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%232585ce' cx='50' cy='300' r='50'/%3E%3Cg fill='%232a89d1' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%232f8ed5' cx='50' cy='400' r='50'/%3E%3Cg fill='%233493d9' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%233998dc' cx='50' cy='500' r='50'/%3E%3Cg fill='%233e9de0' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%2343a2e3' cx='50' cy='600' r='50'/%3E%3Cg fill='%2348a7e7' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%234dabea' cx='50' cy='700' r='50'/%3E%3Cg fill='%2352b0ee' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%2357b5f1' cx='50' cy='800' r='50'/%3E%3Cg fill='%235cbaf5' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%2361bff8' cx='50' cy='900' r='50'/%3E%3Cg fill='%2367c4fc' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%236CC9FF' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-glass {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3px );
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.display-1 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 500;
}

.display-1 > strong {
    font-weight: 700;
}

textarea {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur( 1px ) !important;
    -webkit-backdrop-filter: blur( 1px ) !important;
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    box-shadow: inset .0625rem .125rem .33rem rgba(0, 0, 0, 0.1);
}
